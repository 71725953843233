import { Box, Modal } from "@mui/material";
import React  from "react";

export default function Grivience() {
    const [credits, setCreditS] = React.useState(false);
    const handleOpenCredits = () => setCreditS(true);
    const handleCloseCredits = () => setCreditS(false);
    const [upwords, setupword] = React.useState(false);
    const handleOpenupwords = () => setupword(true);
    const handleCloseUpwords = () => setupword(false);
    const [cashe, setcashe] = React.useState(false);
    const handleOpencheshe = () => setcashe(true);
    const handleCloseCashe = () => setcashe(false);
    const [upwordOpen, setUpwordOpen] = React.useState(false);
    const [loantapOpen, setloantapOpen] = React.useState(false);
    const handleOpenLoanTap = () => setloantapOpen(true);
    const handleOpenUpword = () => setUpwordOpen(true);
    const handleCloseUpword = () => setUpwordOpen(false);

    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: 'background.paper',
        fontSize: '16px',
        p: 4,
    };
    return (
        <div className="mt-24   font-bold">
          

         
          
            
            <div className="grav-text">
                <div className="shadow-2xl ... p-5">
              
                
                <h5 className="text-center our-partner" > Our Lending Partner</h5>
                <div className="flex justify-center gap-5 mt-5">
 
        <div>
          <img
              onClick={handleOpenLoanTap}
            className="mobile-image cursor-pointer w-36"
            src={process.env.PUBLIC_URL + "/assets/images/par3.jpg"}
          ></img>
        </div>
        {/* <div>
          <img
            className="mobile-image w-16 cursor-pointer"
            src={process.env.PUBLIC_URL + "/assets/images/par2.jpeg"}
          ></img>
        </div> */}
        <div>
          <img
            className="mobile-image w-16 cursor-pointer"
            src={process.env.PUBLIC_URL + "/assets/images/part4.png"}
          ></img>
        </div>
      </div>
    
    </div>
    </div>
    </div>
    );
}
