import React from "react";
import {  NavLink } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import { Link } from "react-router-dom";
import Collapse from '@mui/material/Collapse';
import StarBorder from '@mui/icons-material/StarBorder';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useEffect } from "react";
export default function Homepage() { 
 
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
   fontSize:'12px',
   
    p: 4,
  };
  

  const [upwordOpen, setUpwordOpen] = React.useState(false);
  const handleOpenUpword = () => setUpwordOpen(true);
  const handleCloseUpword = () => setUpwordOpen(false);

  const [loantapOpen, setloantapOpen] = React.useState(false);
  const handleOpenLoanTap = () => setloantapOpen(true);
  const handleCloselaonTap = () => setloantapOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  const handleClick1 = (e) => {
    e.preventDefault();
    setOpen1(!open1);
  };
  const handleClick2 = (e) => {
    e.preventDefault();
    setOpen2(!open2);
  };
  const handleClick3 = (e) => {
    setOpen3(!open3);
  };
  const handleClick4 = (e) => {
    setOpen4(!open4);
  };
  const handleClick5 = (e) => {
    setOpen5(!open5);
  };
console.log(process.env);
  return (
    <div>
      <div
        className="p-3 main-home"
      >
        <div className="mainHomePage">
          <img  src={process.env.REACT_APP_PUBLIC_URL + "assets/images/home88.jpg"}></img>
        </div>

        <div className="mainHomePage">

          <div id="welcometextmain">
            <div className="font-bold text-3xl text-black welcome">
              <span style={{color:"#2b4a84"}}>Welcome To ClikFin </span>– Your Instant Life Upgrade
            </div>
            <div className=" mt-3" style={{ listStyle: "initial",color:'#0dcaf0' }}>
              <div className="font-bold text-3xl">Get Up to ₹10 Lakhs Instant Transfer to Bank</div>
            </div>
          </div>
          <div className="list-icon-home">
          <List
          sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper', marginTop: '2rem' }}
          component="nav"
          aria-labelledby="nested-list-subheader">
            <ListItemButton onClick={(e)=>handleClick2(e)}>
              <ListItemIcon>
              <img width={70} height={70}  src={process.env.REACT_APP_PUBLIC_URL + "assets/images/home_logo_2.png"}></img>
              </ListItemIcon>
              <ListItemText primary="Get Instant Credit" />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText >
                    In the fast-paced world of personal finance, seizing opportunities and addressing urgent needs often requires immediate access to credit. ClikFin proudly presents its standout feature – the ability to get instant credit. Here's a brief overview of why this feature stands as the pinnacle of financial convenience with ClikFin.
                    <ul className="mt-2">
                      <li><span className="font-bold">* Immediate Financial Access</span></li>
                      <li><span className="font-bold">* Hassle-Free Application</span></li>
                      <li><span className="font-bold">* Transparent Terms and Conditions</span></li>
                    </ul>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={(e)=>handleClick3(e)}>
              <ListItemIcon>
              <img width={70} height={70} src={process.env.REACT_APP_PUBLIC_URL + "assets/images/home_logo_5.png"}></img>
              </ListItemIcon>
              <ListItemText primary="Instant Transfer to Bank Account" />
              {open3 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText primary="In the dynamic landscape of personal finance, timely access to funds is paramount. ClikFin stands out as a financial ally, and its primary feature sets it apart — the ability to instantly transfer funds directly to your bank account." />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton onClick={(e)=>handleClick4(e)}>
              <ListItemIcon>
              <img width={70} height={70} src={process.env.REACT_APP_PUBLIC_URL + "assets/images/home_logo_4.png"}></img>
              </ListItemIcon>
              <ListItemText primary="Get Home Finance" />
              {open4 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open4} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText>
                  Are you ready to make your dream home a reality? Look no further than the ClikFin App – your trusted partner in turning homeownership dreams into delightful realities!
                    <ul className="mt-2">
                      <li><span className="font-bold">* Seamless Application Process</span></li>
                      <li><span className="font-bold">* Real-time Approval</span></li>
                      <li><span className="font-bold">* Expert Guidance at Your Fingertips</span></li>
                    </ul>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton className="dropdown" onClick={(e)=>handleClick1(e)}>
              <ListItemIcon>
              <img width={70} height={70} src={process.env.REACT_APP_PUBLIC_URL + "assets/images/home_logo_1.png"}></img>
              </ListItemIcon>
              <ListItemText primary="Emergency Fund" />
              {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemText >
                  Ease the burden of unexpected medical expenses with ClikFin medical funds. Don't stress about the costs, let us be your financial support when you need it the most. ClickFin stands out in the medical fund landscape with its seamless and swift application process, competitive interest rates, and flexible repayment options.
                    <ul className="mt-2">
                      <li><span className="font-bold">* Digital Platform and Accessibility</span></li>
                      <li><span className="font-bold">* Flexible Repayment Options</span></li>
                      <li><span className="font-bold">* Instant Approval</span></li>
                    </ul>
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          </List>
          </div>
        </div>
      </div>

      <div className=" text-center text-3xl text-black font-bold ">
        Why Choose Instant  Financial Need?
      </div>
      <div
        className="text-center mt-3 text-black m-auto para-2"
      >
        <p>
          It's A Rapidly Evolving Digital Age, And Clikfin Offers You A Truly
          Modern, Sensible Personal  Financial Need Option. No Lengthy Paperwork, Instant
          Processing Times, Attractive Interest Rates, With No Prepayment
          Charges. Borrow Personal  Financial Need Up To ₹ 10 Lakh And Have It Transferred
          To Your Bank Account Within Minutes. Fully Digital Journey Available
          24X7
        </p>
      </div>
      {/* <div className="font-bold text-center text-3xl mt-5">How ClikFin Works?</div>
      <div>
        <img
          className=" how-clik"
          src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/home22.png"}
        ></img>
      </div> */}
      <div className=" text-center text-3xl text-black  font-bold">
        Our Awesome Testimonials
      </div>
      <div className="text-center mt-3 text-black para-2">
        <p>
          We Believe Helping People During Need Is A Best Deed, Thus Your
          Blessings Makes Us Unique.
        </p>
      </div>
      <div className="flex gap-5  justify-center text-black p-3 ">
        <Carousel>
          <Carousel.Item>
            <div className=" gap-5  justify-center text-black p-3 testmonials">
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “I used ClikFin to help me pay for my travel expenses. The
                    service was great – fast but still friendly. Fantastic and
                    Painless Experience. Thank you.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl">
                      Swati Joshi
                    </div>
                    <div>Mumbai</div>
                  </div>
                </div>
              </div>
              
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “These guys are always ready to help when you need it most.
                    They helped cover school fees for my brother and I when I
                    was a little low on cash.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl font-bold">
                      Purnima Rawat
                    </div>
                    <div>Kanpur</div>
                  </div>
                </div>
              </div>
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “Their application process was quick and simple. in a short
                    time I had the funds I needed to complete some much needed
                    improvements around the house.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl font-bold">
                      Kartik Patel
                    </div>
                    <div>Ahmedabad</div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className=" gap-5 justify-center text-black p-3 testmonials">
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “I used ClikFin to help me pay for my travel expenses. The
                    service was great – fast but still friendly. Fantastic and
                    Painless Experience. Thank you.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl font-bold">
                      Swati Joshi
                    </div>
                    <div>Mumbai</div>
                  </div>
                </div>
              </div>
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “These guys are always ready to help when you need it most.
                    They helped cover school fees for my brother and I when I
                    was a little low on cash.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl font-bold">
                      Purnima Rawat
                    </div>
                    <div>Kanpur</div>
                  </div>
                </div>
              </div>
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “Their application process was quick and simple. in a short
                    time I had the funds I needed to complete some much needed
                    improvements around the house.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl font-bold">
                      Kartik Patel
                    </div>
                    <div>Ahmedabad</div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className=" gap-5  justify-center text-black p-3 testmonials">
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “I used ClikFin to help me pay for my travel expenses. The
                    service was great – fast but still friendly. Fantastic and
                    Painless Experience. Thank you.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl font-bold">
                      Swati Joshi
                    </div>
                    <div>Mumbai</div>
                  </div>
                </div>
              </div>
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “These guys are always ready to help when you need it most.
                    They helped cover school fees for my brother and I when I
                    was a little low on cash.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl font-bold">
                      Purnima Rawat
                    </div>
                    <div>Kanpur</div>
                  </div>
                </div>
              </div>
              <div className=" p-5 rounded-lg ... clickfin-para-home text-black border-2 border-solid" style={{ background: '#bdbde3' }}>
                <div>
                  <p>
                    “Their application process was quick and simple. in a short
                    time I had the funds I needed to complete some much needed
                    improvements around the house.”
                  </p>
                </div>
                <div className="flex justify-center">
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                  <div>
                    <img
                      className="w-5 h-5"
                      src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/star1.png"}
                    ></img>
                  </div>
                </div>
                <div className="flex gap-5 mt-5">
                  <div>
                    <div className="text-yellow-600 font-bold text-xl font-bold">
                      Kartik Patel
                    </div>
                    <div>Ahmedabad</div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="font-bold text-center text-3xl text-black mt-12">
      Our Lending Partners
      </div>
      {/* <Modal
  open={upwordOpen}
  onClose={handleCloseUpword}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <div className="mt-5">
          <table className="m-auto w-11/12" >
            <tr>
              <th>BANK/NBFC</th>
              <td>Upwards</td>
            </tr>
            <tr>
              <th>Interest Rate(pa)</th>
              <td>16.00 % to 32%</td>
            </tr>
            <tr>
              <th> Financial Need Amount </th>
              <td>20,000 Thousand to 5 Lakhs</td>
            </tr>
            <tr>
              <th>Processing Fees </th>
              <td>Up to 2 % to 4 % of the  financial need amount</td>
            </tr>
            <tr>
              <th>Part Payment</th>
              <td>Up to 40% of  financial need amount every year</td>
            </tr>
            <tr>
              <th>Pre-closure Charges</th>
              <td>Upto 5% on your principal outstanding + GST</td>
            </tr>
            <tr>
              <th>Locking Period</th>
              <td>3 Month</td>
            </tr>
            <tr>
              <th>Tenure</th>
              <td>12 to 48 months</td>
            </tr>
          </table>
        </div>
  </Box>
</Modal> */}
<div id="floating-icon-android">
              <Fab color="primary" aria-label="add">
                <Link to="https://play.google.com/store/apps/details?id=com.clikfin.clikfinapplication">
                  <img
                    style={{ width: '60px' }}
                    className="mobile-image"
                    alt="android"
                    src={"https://miro.medium.com/v2/resize:fit:324/1*XwETOHbx50_CT4tRbbBdog.png"}
                  ></img>
                </Link>
                </Fab>
                </div>
                <div id="floating-icon-ios">
                <Fab color="primary" aria-label="add">
                <Link to="https://apps.apple.com/in/app/clikfin-one-click-away/id1658734808">
                  <img
                    style={{ width: '60px' }}
                    className="mobile-image"
                    alt="ios"
                    src={"https://cdn2.iconfinder.com/data/icons/system-flat-buttons/512/apple-512.png"}
                  ></img>
                </Link>
              </Fab>
              </div>
<Modal
  open={loantapOpen}
  onClose={handleCloselaonTap}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <div className="mt-5">
          <table className="m-auto w-11/12" style={{fontSize:'12x'}}>
            <tr>
              <th>BANK/NBFC</th>
              <td> Financial Need Tap</td>
            </tr>
            <tr>
              <th>Interest Rate(pa)</th>
              <td>16.00 % to 24%</td>
            </tr>
            <tr>
              <th> Financial Need Amount </th>
              <td>50 Thousand to 10 Lakhs</td>
            </tr>
            <tr>
              <th>Processing Fees </th>
              <td>2 % of the  financial need amount</td>
            </tr>
            <tr>
              <th>Part Payment</th>
              <td>Up to 40% of  financial need amount every year</td>
            </tr>
            <tr>
              <th>Pre-closure Charges</th>
              <td>
                <ul>
                  <li>0-6 Months – 5% of Principal Outstanding</li>
                  <li>6 months – 0%</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>Locking Period</th>
              <td>6 Month</td>
            </tr>

            <tr>
              <th>Tenure</th>

              <td>12 to 60 months</td>
            </tr>
          </table>
        </div>
  </Box>
</Modal>
      <div className="flex justify-center gap-5 mt-5">
          <div>
          <img
              onClick={handleOpenLoanTap}
          
            className="mobile-image cursor-pointer w-36"
            src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/par3.jpg"}
          ></img>
        </div>

        {/* <div>
          <img
            className="mobile-image w-16 cursor-pointer"
            src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/par2.jpeg"}
          ></img>
        </div> */}
        <div>
          <img
            className="mobile-image w-16 cursor-pointer"
            src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/part4.png"}
          ></img>
        </div>
      </div>

   
       
     
    
      <div className="font-bold text-center text-3xl text-black mt-12 heading-2">
        Introducing ClikFin - Your Gateway To Financial Solutions
      </div>
      <div className="flex justify-around text-black mt-12 para-3">

        <div className="about ml-1">
        <div className=" font-bold">About</div>
        <div className="p-1">
          <div>
            <p>
              ClikFin, a proud venture wholly owned by CygniSys Services Pvt.
              Ltd. Formerly known as (CygniSys OPC Pvt. Ltd.), established in
              2021, is an innovative online platform that empowers customers
              with a wide array of financial products and services, specializing
              in personal  financial needs. Our primary mission is to bridge the gap
              between borrowers and lenders, ensuring they secure the most
              favorable and tailored deals available in the market.
            </p>
          </div>
          <div className=" font-bold mt-2">Our Vision:</div>
          <div className=" pl-1 pt-1">
            <p>
              ClikFin’s vision is to empower individuals by providing them with
              the necessary financial tools and support to achieve their
              aspirations. We are dedicated to delivering exceptional customer
              service and guidance throughout your financial voyage.
            </p>
          </div>
          <div className="text-black mt-2">
            <p>
              Experience the power of choice with ClikFin. Let us be your
              trusted companion in making informed financial decisions. Begin
              exploring our comprehensive range of financial products and
              services today, and take a significant step towards securing a
              brighter financial future.
            </p>
          </div>
        </div>
        </div>
        <div className="about unique">
        <div className="font-bold">What Makes Us Unique?</div>
      <div className="">
        <div className="pl-1">
          <div className="text-black">
            <p>
              <span className="font-bold "> 1. Transparency: </span> Clear and
              open communication throughout the  financial need process, ensuring you
              understand every step .
            </p>
          </div>
          <div className="mt-3 text-black">
            <p>
              <span className="font-bold ">
                {" "}
                2. Super-Fast  Financial Need Disbursement: :{" "}
              </span>{" "}
              : We understand your requirement, We ensure that your application
              is fast-tracked and the funds reaches your account within promised
              timeline
            </p>
          </div>
          <div className="mt-3 text-black">
            <p>
              <span className="font-bold "> 3. Great Customer Success: </span>{" "}
              Customer Obsession is the back bone of our organization, We always
              listen to the customer and provide swift response for a
              hassle-free  financial need
            </p>
          </div>
          <div className="mt-3 text-black">
            <p>
              <span className="font-bold "> 4. We Do What We Promise: </span>A
              commitment to delivering on our promises, providing reliability
              and trustworthiness in our services.
            </p>
          </div>
          <div className="mt-3 text-black">
            <p>
              <span className="font-bold "> 5. Not an NBFC: </span>It is
              essential to understand that ClikFin is not an NBFC itself. We do
              not engage in co-lending or FLDG based lending. Our expertise lies
              in connecting you with reputable lenders to meet your borrowing
              requirements.
            </p>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
  );
}
