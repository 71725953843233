import React, { useState } from "react";
import FAQ from "./FAq";

export default function Personel() {
  const [faqs1, setFaqs1] = useState([
    {
      question: "Are Personal  Financial Needs a Wise Choice?",
      answer:
        "Personal  financial needs can be a prudent option, especially during financial crises, as they provide quick access to funds without requiring collateral.",
      open: true,
    },
    {
      question: "How Can I Apply for a Personal  Financial Need?",
      answer:
        "To apply for a personal  financial need, you must meet specific eligibility criteria, including age requirements, salaried employment, a good credit score, and a minimum monthly income.",
      open: false,
    },
    {
      question: "What Determines Personal  Financial Need Eligibility?",
      answer:
        " Eligibility for a personal  financial need is based on factors such as age, employment status, credit score, and minimum monthly income.",
      open: false,
    },
    {
      question: "Do Personal  Financial Needs Affect Your Credit Score? ",
      answer:
        "Personal  financial needs can impact your credit score, and missed repayments can have negative consequences due to the unsecured nature of these  financial needs.",
      open: false,
    },
    {
      question: "How Long Does Personal  Financial Need Approval Take? ",
      answer:
        " With the digitization of  financial need processes, personal  financial need approvals have become faster, often instant. However, the disbursal of funds may take up to 24 hours due to documentation procedures.",
      open: false,
    },

    {
      question: "Can I Repay a Personal  Financial Need Before the Tenure Ends?",
      answer:
        " Yes, you can repay a personal  financial need before the tenure ends through prepayment. However, some lenders may charge a pre closure charges for repayment.",
      open: false,
    },

    {
      question: "What Is the Average Interest Rate on Personal  Financial Needs? ",
      answer:
        "  The average interest rate on personal  financial needs typically ranges from 16.00% to 32%, but this can vary among different lenders.",
      open: false,
    },

    {
      question:
        "What Factors Do Banks Consider During Personal  Financial Need Applications?  ",
      answer:
        " When applying for a personal  financial need, banks consider various factors, including age, monthly income, credit score, credit history, and necessary documentation.",
      open: false,
    },
    {
      question: "Which Lender Offers the Best Personal  Financial Need Rates? ",
      answer:
        "  The best personal  financial need rates depend on the borrower’s credit profile, including a good credit score, consistent income source, and salaried employment.",
      open: false,
    },
    {
      question: "What Is the Minimum Salary Requirement for a Personal  Financial Need?  ",
      answer:
        " While the minimum salary requirement may vary among lenders, most banks and NBFCs typically require a monthly income of ₹20,000 or more for personal  financial need approval.",
      open: false,
    },
  ]);
  const toggleFAQ = (index) => {
    setFaqs1(
      faqs1.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <div className="text-black per- financial need-back mt-24">
       <div className="font-bold text-3xl mt-5 text-center">
              Benefits And Features Of Unsecured Personal  Financial Needs
            </div>
      <div className="main-per gap-2">
      <div>
          <div className="mt-5">
            <img className="text-center" src={process.env.PUBLIC_URL + "/assets/images/bacr.png"}></img>
          </div>
         
                </div>
       
        <div>
       
          <div>
           
            <div className=" text-black rounded-xl mt-2 ">
              <div className="m-auto no-contra p-2">
               
                <div className="text-black mt-5">
                  <div className="font-bold"> 1. Minimal Documentation:</div>
                  <div>
                    {" "}
                    Streamlined digital application process for swift approval.
                  </div>
                </div>
                <div className="text-black mt-2">
                  <div className="font-bold"> 2. Easy Approval:</div>
                  <div>
                    {" "}
                    Quick verification for fast access to funds during
                    emergencies.
                  </div>
                </div>
                
            <div className="text-black mt-2">
              <div className="font-bold">3. Multipurpose</div>
              <div>
                {" "}
                Versatile use for various needs like weddings, home renovations,
                or immediate financial requirements.
              </div>
            </div>
            <div className="text-black mt-2">
              <div className="font-bold">4. Low-Interest Rates:</div>
              <div>
                Eligibility and good credit history can secure competitive
                rates, saving money.
              </div>
            
          </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="font-bold text-3xl mt-5 text-center">
        Personal  Financial Need Eligibility
      </div>
      <div className="mt-3  ml-5 rounded-xl text-black m-auto w-2/4">
        <p className="ml-5">
          Personal  financial needs are offered by most banks and NBFCs, each with their
          own specific eligibility criteria. Nonetheless, there exist certain
          common requirements across all financial institutions. The eligibility
          criteria to get an online personal  financial need approval for salaried and
          self-employed individuals are:
        </p>
      </div>

      <div className="mt-5 p-5  rounded-xl text-black">
        <table className="m-auto w-11/12 personal_need_table" style={{ border: "1px solid black" }}>
          <tr>
            <th>Age</th>
            <td>23 years – 55 years</td>
          </tr>
          <tr>
            <th>Employment Type</th>
            <td>1. Salaried</td>
          </tr>
          <tr>
            <th>Credit score</th>
            <td>650 or above with a good credit history</td>
          </tr>
          <tr colspan="2">
            <td>Minimum Net Income (Monthly)</td>
              <td>₹25,000  (non-metro cities)
              <span className="metro">₹20,000  (metro cities)</span></td>
          </tr>
          <tr>
            <th> Financial Need Amount</th>
            <td>Up to ₹10 lacs depending on the credit profile</td>
          </tr>
          <tr>
            <th>Work Experience</th>
            <td>Employed at current company for at least 6/12 months</td>
          </tr>
        </table>
      </div>
      <div className="font-bold text-3xl mt-5 text-center text-black">
        Documents Required For Personal  Financial Need
      </div>
      <div className="  rounded-xl text-black">
        <div className="mt-2 text-center">
          Below are the list of the typical documents needed for the approval of
          an online personal  financial need:
        </div>
        <div className="font-bold mt-2 text-center">Salaried Individuals</div>
        <ol className="number-list">
          <li>Proof of Identity and Age</li>
          <li>
            Fully completed personal  financial need application form with a photograph
          </li>
          <li>PAN Card</li>
          <li>
            Proof of Residence – Passport, driving license, Voter ID,
            postpaid/landline bill, utility bills (electricity/water/gas)
          </li>
          <li>
            Bank statements from the last three months (preferably from your
            salary account)
          </li>
          <li>Last three-month pay slips</li>
        </ol>
      </div>

      <div className="text-3xl font-bold mt-5 text-center">
        Representative Example:
      </div>
      <div className=" rounded-xl mt-5 text-black m-auto example">
        <div className="mt-5 "> Financial Need Amount: ₹1,00,000</div>
        <div>Tenure: 36 months</div>
        <div>Interest Rate: @18%* (reducing basis)</div>
        <div>EMI: ₹3,615</div>
        <div>
          Total Interest Payable: ₹3,615 x 36 months – ₹1,00,000 Principal =
          ₹30,140
        </div>
        <div>Processing fee (@ 2%) = ₹2,000 + GST = ₹2,360</div>
        <div>Disbursed Amount: ₹1,00,000 – ₹2,360 = ₹97,640</div>
        <div>Total Amount Payable: ₹3,615 x 36 months = ₹1,30,140</div>
        <div>
          Total Cost of the  Financial Need = Interest Amount + Processing Fees = ₹30,140 +
          ₹2,360 = ₹32,500
        </div>
      </div>

      <div className="faqs text-black ">
        {faqs1.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>

      <div className="mt-5 ml-5 text-center m-auto w-11/12">
        Explore the world of personal  financial needs with confidence, knowing the answers
        to these frequently asked questions. ClikFin offers a seamless  financial need
        application process, ensuring you get the financial support you need for
        various purposes. Apply now and experience the convenience of our
        instant personal  financial need service.
      </div>
    </div>
  );
}
