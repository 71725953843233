import { Box, Modal } from "@mui/material";
import { useEffect,useState,useCallback } from "react";
import * as React from 'react';
import { Link } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoginIcon from '@mui/icons-material/Login';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SideNav from "./SideNav";

export default function Header() {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [windowSize, setWindowSize] = React.useState(window.innerWidth);
  const opened = Boolean(anchorEl);

  const handleClose = () => setOpen(false);
  const[flag, setFlag] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleClick = () => {
    setFlag(!flag);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const hideNav = () => {
    document.getElementById("drawer-toggle-label").click();
  };
const handleWindowResize = useCallback(event => {
    setWindowSize(window.innerWidth);
}, []);

useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
}, [handleWindowResize]);
useEffect(()=>{
console.log('windowSize',windowSize);
},[windowSize])

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };
  return (
    <>
    {(windowSize >= 758) ? <div className="sticky-header">
      <div className="flex justify-between ... header-wrapper ">
        <div>
          <Link className="no-underline  " to="/">
            <img
              className="ml-2 comp_logo"
              alt="logo"
              src={process.env.REACT_APP_PUBLIC_URL + "assets/images/homeLogo.png"}
            ></img>
          </Link>{" "}
        </div>
        <input type="checkbox" id="drawer-toggle" name="drawer-toggle" />
        {/* <img style={{width:'15px',height:'15px'}}
              className="w-40 drawer-toggle"
              alt="logo"
              src={process.env.PUBLIC_URL + '/assets/images/cross.png'}
            ></img> */}
        <label for="drawer-toggle" id="drawer-toggle-label"></label>
        <nav id="drawer">
          <ul className="sidemenu">
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/"
              >
                <div>Home</div>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/about"
              >
                <div>About</div>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/personelloan"
              >
                <div>Personal  Financial Need</div>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/emicalculator"
              >
                <div>EMI Calculator</div>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/blog"
              >
                <div>Blog</div>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/terms"
              >
                <div>Terms & condition</div>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/privacy"
              >
                <div>Privacy Policy</div>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/faqs"
              >
                <div>FAQS</div>
              </Link>
            </li>
            <li>
              <Link
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="/contact"
              >
                <div>Contact</div>
              </Link>
            </li>
            <li>
              <Link target="_blank"
                onClick={() => hideNav()}
                style={{ textDecoration: "none" }}
                to="https://play.google.com/store/apps/details?id=com.clikfin.clikfinapplication"
              >
                <div className="get-cash-now rounded-full ... p-2">
                  GET CASH NOW
                </div>
              </Link>
            </li>

            {/* <li>
      <Link style={{ cursor: "pointer",textDecoration:'none' }}>
        <div
          onClick={handleOpen}
          className="get-cash-now rounded-full ... p-2"
        >
          Log In
        </div>
      </Link>
    </li> */}
          </ul>
        </nav>
        <div className="flex p-5  wrapper-gap  " style={{ gap: "20px", fontSize: '13px', display:"flex", flexDirection:"row" }}>
          <Link className="no-underline  " to="/">
            <div className="home">Home</div>
          </Link>
          <Link
            className="no-underline  "
            to="/about"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <div className="home">About</div>
          </Link>
          <Link
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            className="no-underline  "
            to="/personelloan"
          >
            <div className="home">Personal  Financial Need</div>
          </Link>

          <Link
            className="no-underline  "
            to="/emicalculator"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <div className="home">EMI Calculator</div>
          </Link>
          {/* <Link
            className="no-underline"
            style={{ color: "#2B4A84" }}
            to="/faqs"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <div className="home">FAQs</div>
          </Link> */}
          {/* <Link
            className="no-underline"
            style={{ color: "#2B4A84" }}
            to="/blog"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <div className="home">Blog</div>
          </Link> */}

          <Link
            className="no-underline  "

            to="/contact"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <div className="home">Contact</div>
          </Link>
          {false && <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
      <Select
        value="9" 
        displayEmpty
      >
        <MenuItem value="9">
            <em>Forms</em>
          </MenuItem>
        <MenuItem value="8">
        <FactCheckOutlinedIcon color="primary"/>
          <div className="ml-2">
            <Link
              to="/checkdupcusform"
            >
              checked up form
            </Link>
          </div>
        </MenuItem>
        <MenuItem value="1">
        <ApprovalOutlinedIcon color="primary"/>
        <div className="ml-2">
          <Link
            to="/preapproval"
          >
            Pre Approval
          </Link>
        </div>
        </MenuItem>
        <MenuItem value="2">
        <PaidOutlinedIcon color="primary" />
        <div className="ml-2">
          <Link
            to="/planbasedOnsalary"
          >
            Plan Based On Salary
          </Link>
        </div>
        </MenuItem>
        <MenuItem value="3">
          <PersonAddOutlinedIcon color="primary"/>
          <div className="ml-2">
            <Link
              to="/createcustomer"
            >
              Create Customer
            </Link>
          </div>
        </MenuItem>
        <MenuItem value="4">
          <NoAccountsOutlinedIcon color="primary"/>
          <div className="ml-2">
            <Link
              to="/addingdeactivatemurchant"
            >
              Adding Deactivate Murchant
            </Link>
          </div>
        </MenuItem>
        <MenuItem value="5">
          <HowToRegOutlinedIcon color="primary"/>
          <div className="ml-2">
            <Link
              to="/customerstatus"
            >
              Check Customer Status
            </Link>
          </div>
        </MenuItem>
        <MenuItem value="6">
          <DynamicFeedOutlinedIcon color="primary"/>
          <div className="ml-2">
            <Link
              to="/bulkcustomerstatus"
            >
              Check Bulk Customer Status
            </Link>
          </div>
        </MenuItem>
        <MenuItem value="7">
          <BackupOutlinedIcon color="primary"/>
          <div className="ml-2">
            <Link
              to="/documentupload"
            >
              Document Upload
            </Link>
          </div>
        </MenuItem>
      </Select>
    </FormControl>
  </Box> }
  


          {/* <Link to="https://play.google.com/store/apps/details?id=com.clikfin.clikfinapplication">
            <img
              style={{ width: '50px' }}
              className="mobile-image"
              alt="google"
              src={process.env.PUBLIC_URL + "/assets/images/androied.svg"}
            ></img>
          </Link>

          <Link to="https://apps.apple.com/in/app/clikfin-one-click-away/id1658734808">
            <img
              style={{ width: '50px' }}
              className="mobile-image"
              alt="apple"
              src={process.env.PUBLIC_URL + "/assets/images/ios.svg"}
            ></img>
          </Link> */}
          <Link
            className="no-underline"
            style={{ color: "#2B4A84" }}
            to="https://play.google.com/store/apps/details?id=com.clikfin.clikfinapplication"
          >
            <div className="get-cash-now ... p-2">
              Get Instant Cash <CurrencyRupeeIcon />
            </div>
          </Link>
          {/* <div id="menu">
  <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="medium"
            sx={{ ml: 2 }}
            aria-controls={opened ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={opened ? 'true' : undefined}
          >
            <Avatar sx={{ width: 40, height: 40, backgroundColor:'#435360' }}>C</Avatar>
          </IconButton>
        </Tooltip>
      </Box>

  <div id="menuBox">
      {flag &&  <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <AppRegistrationIcon />
          </ListItemIcon>
          <ListItemText primary="Register" />
        </ListItemButton>
      </List>
    </Box>}  
    </div>
  </div> */}
          

      {/* <label class="hamburger-menu align-right">
        <input type="checkbox" />
      </label>
      <aside class="sidebar">
        <nav>
          <a>login</a>
          <a>Get Instant Cash</a>
        </nav>
      </aside> */}

          {/* <Link
            className="cursor-pointer no-underline"
            style={{ color: "#2B4A84" }}
          >
            <div
              onClick={handleOpen}
              className="get-cash-now rounded-full ... p-2"
            >
              Log In
            </div>
          </Link>
         */}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <div>
                <div className="font-bold"> Almost There</div>
                <div className="mt-3">
                  Please enter your phone number to proceed
                </div>
                <div className="mt-5">
                  {/* <PhoneInput
                    specialLabel={""}
                    country={"in"}
                    placeholder="Phone Number"
                    className="phone-input"
                  /> */}
                  <input
                    type="number"
                    className="phone-input"
                    placeholder="Phone Number"
                    max="10"
                    min="2"
                    maxLength={10}
                  ></input>
                </div>
                <div className="mt-3 proceed">Proceed</div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  : <SideNav/>}
    </>
  );
}
