import React from 'react'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { Link } from "react-router-dom";

function Button() {
  return (
    <Link
            className="no-underline"
            style={{ color: "#2B4A84" }}
            to="https://play.google.com/store/apps/details?id=com.clikfin.clikfinapplication"
          >
            <div className="get-cash-now ... p-2">
              Get Instant Cash <CurrencyRupeeIcon />
            </div>
    </Link>
  )
}

export default Button;